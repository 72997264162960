import "@cloudscape-design/global-styles/index.css";

import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useLocalStorage, useLocation } from "react-use";

import { AppLayout, SplitPanel } from "@cloudscape-design/components";

import AbvCalculator from "./components/AbvCalculator";
import Hint from "./components/Hint";
import HoneyCalculator from "./components/HoneyCalculator";
import { AuthContextProvider } from "./contexts/AuthContext";
import GetAuthContext from "./contexts/GetAuthContext";
import Approve from "./layout/Approve";
import Loginout from "./layout/Loginout";
import { PrintRouting } from "./layout/PrintRouting";
import { Routing } from "./layout/Routing";
import Sidebar from "./layout/Sidebar";
import { UserData } from "./models/UserData";
import version from "./v.json";

function App() {
  const location = useLocation();
  const [nav, setNav] = useLocalStorage("nav", true);

  const [tool, setTool] = useLocalStorage("tool", false);
  const [userObject, setUserObject] = useState<UserData | null>(null);

  return (
    <AuthContextProvider>
      <GetAuthContext setUserObject={setUserObject} />
      <Router>
        {location.pathname?.endsWith("/print") ? (
          <PrintRouting />
        ) : (
          <AppLayout
            navigationOpen={nav}
            onNavigationChange={({ detail }) => setNav(detail.open)}
            toolsOpen={tool}
            onToolsChange={({ detail }) => setTool(detail.open)}
            splitPanel={
              <SplitPanel
                header={`${
                  userObject?.name ?? "No user"
                } - The Horrible Meadery © 2024 ${
                  version.MajorMinorPatch +
                  "." +
                  version.CommitsSinceVersionSource
                }`}
                hidePreferencesButton
              >
                <p>Latest changes:</p>
                <ul>
                  <li>
                    <strong>Change:</strong> Introduced Cloudscape Board
                    components to the Home page layout.
                    <br />
                    <strong>Details:</strong> The Home page now features an
                    updated layout with <code>BoardItem</code> and{" "}
                    <code>Board</code> components, allowing users to manage and
                    display widgets in a more interactive and customizable way.
                  </li>
                  <li>
                    <strong>Change:</strong> Added support for saving widget
                    layout using local storage.
                    <br />
                    <strong>Details:</strong> Users' widget layouts on the Home
                    page are now persisted across sessions using local storage,
                    ensuring a personalized experience when returning to the
                    app.
                  </li>
                  <li>
                    <strong>Change:</strong> Added a "Next Steps" widget to the
                    Home page layout.
                    <br />
                    <strong>Details:</strong> A new widget, "Next Steps," is now
                    available on the Home page to provide users with actionable
                    tasks and updates related to their batches or other
                    processes.
                  </li>
                </ul>
              </SplitPanel>
            }
            navigation={
              <>
                <Sidebar />
                <Approve />
                <Loginout />
              </>
            }
            content={<Routing />}
            tools={
              <div style={{ marginTop: 40, padding: 8 }}>
                <Hint />
                <AbvCalculator />
                <HoneyCalculator />
              </div>
            }
          />
        )}
      </Router>
    </AuthContextProvider>
  );
}

export default App;
