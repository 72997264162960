import { Step } from "../models/Recipe";

export function getMonthName(date: Date) {
  return date.toLocaleString("en-US", { month: "long" });
}

export const sumStepDays = (steps: Step[], currentStep: number) => {
  let sum = 0;
  for (let i = 0; i < steps.length; i++) {
    if (i - 1 === currentStep) {
      return sum;
    } else {
      sum += steps[i].duration;
    }
  }
};

export function addDaysToDate(date: Date, daysToAdd: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return result;
}

export function calculateTimeLeft(targetDate: Date) {
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

export const daysUntilDate = (date: Date) => {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const timeDiff = date.getTime() - Date.now();
  const daysDiff = Math.floor(timeDiff / millisecondsPerDay);

  return daysDiff >= 0 ? daysDiff : -1;
};
