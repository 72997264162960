/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  ContentLayout,
  ExpandableSection,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import {
  fetchBatchesByStatus,
  subscribeToBatchesByStatus,
} from "../api/batches";
import {
  createShoppingListStatus,
  fetchShoppingList,
  updateShoppingListStatus,
} from "../api/shopping";
import { useAuthContext } from "../contexts/AuthContext";
import { Batch } from "../models/Batch";
import InventoryItem from "../models/InventoryItem";
import { RecipeIngredient } from "../models/Recipe";
import { ShoppingListStatus } from "../models/ShoppingList";

const initShoppingListStatus: ShoppingListStatus = {
  id: "",
  items: [],
};

export const ShoppingList = () => {
  const { tenant } = useAuthContext();
  const [batches, setBatches] = useState<Batch[]>([]);
  const [ingredients, setIngredients] = useState<RecipeIngredient[]>([]);
  const [shoppingListStatus, setShoppingListStatus] =
    useState<ShoppingListStatus>();

  useEffect(() => {
    fetchShoppingList(tenant).then((r) => {
      if (r.length > 0) setShoppingListStatus(r[0]);
      else setShoppingListStatus(initShoppingListStatus);
    });
  }, []);

  useEffect(() => {
    if (!shoppingListStatus) return;
    if (!shoppingListStatus?.id) {
      createShoppingListStatus(shoppingListStatus, tenant);
    } else {
      updateShoppingListStatus(shoppingListStatus, tenant);
    }
  }, [shoppingListStatus]);

  useEffect(() => {
    fetchBatchesByStatus("planned", tenant).then(setBatches);
    const unsubscribe = () =>
      subscribeToBatchesByStatus(["planned"], tenant, setBatches);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const ingredientsToSet: RecipeIngredient[] = [];
    if (!batches || batches.length === 0) return;

    batches.forEach((batch) => {
      batch.recipe?.ingredients.forEach((ingredient) => {
        const found = ingredientsToSet.find(
          (i) => i.item.name === ingredient.item.name
        );
        if (found) {
          found.quantity += Math.round(
            ingredient.quantity *
              (batch.quantity / (batch.recipe?.batchSize ?? 1))
          );
        } else {
          ingredient.quantity = Math.round(
            ingredient.quantity *
              (batch.quantity / (batch.recipe?.batchSize ?? 1))
          );
          ingredientsToSet.push(ingredient);
        }
      });
    });
    setIngredients(ingredientsToSet);
  }, [batches]);

  const handleClear = () => {
    setShoppingListStatus((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        items: [],
      };
    });
  };

  const totalItem = (ingredients: RecipeIngredient[]) => {
    const sumRPrice = ingredients
      .map((i) => (i?.item?.price ?? 0) * i.quantity)
      .reduce((a, b) => a + b, 0);

    const sumRShopping = ingredients
      .map((i) => {
        const inInventory = i.item.quantity ?? 0;
        const need = i.quantity;

        const diff = inInventory - need;

        if (diff < 0) {
          return Math.abs(diff) * (i?.item?.price ?? 0);
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    return {
      item: {
        id: "total",
        name: "Total",
        price: sumRPrice,
        quantity: sumRShopping,
        unit: "€",
      } as InventoryItem,
      quantity: 0,
    };
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description="Hint: Check what is in our inventory"
          actions={
            <Button onClick={handleClear} variant="primary">
              Clear
            </Button>
          }
        >
          Shopping List
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ExpandableSection variant="container" headerText="Cost overveiw">
          <ColumnLayout columns={2}>
            <SpaceBetween size="m" direction="horizontal" alignItems="center">
              <Box variant="awsui-key-label">Cost of ingredients</Box>
              <div>
                {ingredients
                  .map((i) => (i?.item?.price ?? 0) * i.quantity)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
                €
              </div>
            </SpaceBetween>
            <SpaceBetween size="m" direction="horizontal" alignItems="center">
              <Box variant="awsui-key-label">Cost of shopping</Box>
              <div>
                {ingredients
                  .map((i) => {
                    const inInventory = i.item.quantity ?? 0;
                    const need = i.quantity;

                    const diff = inInventory - need;

                    if (diff < 0) {
                      return Math.abs(diff) * (i?.item?.price ?? 0);
                    }
                    return 0;
                  })
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
                €
              </div>
            </SpaceBetween>
            <SpaceBetween size="m" direction="horizontal" alignItems="center">
              <Box variant="awsui-key-label">Cost of remaining</Box>
              <div>
                {ingredients
                  .map((i) => {
                    if (shoppingListStatus?.items.includes(i.item.id ?? ""))
                      return 0;
                    const inInventory = i.item.quantity ?? 0;
                    const need = i.quantity;

                    const diff = inInventory - need;

                    if (diff < 0) {
                      return Math.abs(diff) * (i?.item?.price ?? 0);
                    }
                    return 0;
                  })
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
                €
              </div>
            </SpaceBetween>
          </ColumnLayout>
        </ExpandableSection>
      </div>
      <div style={{ marginTop: 25 }}>
        <Table
          variant="embedded"
          columnDefinitions={[
            {
              header: "",
              cell: (item: RecipeIngredient) =>
                item?.item.id === "total" ? null : (
                  <Checkbox
                    disabled={item.item.quantity >= item.quantity}
                    onChange={({ detail }) => {
                      if (detail.checked) {
                        setShoppingListStatus((prev) => {
                          if (!prev) return prev;
                          return {
                            ...prev,
                            items: [...prev.items, item.item.id ?? "Unknown"],
                          };
                        });
                      } else {
                        setShoppingListStatus((prev) => {
                          if (!prev) return prev;
                          return {
                            ...prev,
                            items: prev.items.filter(
                              (i) => i !== item.item.id ?? "Unknown"
                            ),
                          };
                        });
                      }
                    }}
                    checked={
                      item.item.quantity >= item.quantity ||
                      (shoppingListStatus?.items.includes(
                        item.item.id ?? "Unknown"
                      ) ??
                        false)
                    }
                  />
                ),
            },
            {
              header: "Name",
              cell: (item: RecipeIngredient) =>
                item?.item.id === "total" ? (
                  <strong>{item.item.name}</strong>
                ) : (
                  item.item.name
                ),
            },

            {
              header: "Inventory",
              cell: (item: RecipeIngredient) =>
                item?.item.id === "total"
                  ? null
                  : (item.item.quantity ?? 0) + " " + item.item.unit,
            },
            {
              header: "Recipe",
              cell: (item: RecipeIngredient) =>
                item?.item.id === "total"
                  ? null
                  : item.quantity + " " + item.item.unit,
            },
            {
              header: "Price",
              cell: (item: RecipeIngredient) =>
                item?.item.id === "total"
                  ? (item.item.price ?? 0).toFixed(2) + " €"
                  : ((item.item.price ?? 0) * item.quantity).toFixed(2) + " €",
            },
            {
              header: "Shopping",
              cell: (item: RecipeIngredient) => {
                if (item?.item.id === "total") return null;
                const inInventory = item.item.quantity ?? 0;
                const need = item.quantity;

                const diff = inInventory - need;

                if (diff < 0) {
                  return Math.abs(diff) + " " + item.item.unit;
                }
                return "0 " + item.item.unit;
              },
            },
            {
              header: "Price",
              cell: (item: RecipeIngredient) => {
                if (item?.item.id === "total")
                  return (item.item.quantity ?? 0).toFixed(2) + " €";
                const inInventory = item.item.quantity ?? 0;
                const need = item.quantity;

                const diff = inInventory - need;

                if (diff < 0) {
                  return (
                    (Math.abs(diff) * (item.item.price ?? 0)).toFixed(2) + " €"
                  );
                }
                return "0 €";
              },
            },
          ]}
          items={[...ingredients, totalItem(ingredients)]}
        />
      </div>
    </ContentLayout>
  );
};
