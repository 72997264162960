/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { v4, validate, version } from "uuid";

import {
  Box,
  Button,
  ContentLayout,
  FormField,
  Header,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import { createItem, deleteItem, updateItem } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import InventoryItem from "../models/InventoryItem";
import { Planned } from "../models/Planned";
import { Recipe } from "../models/Recipe";
import { prefixCollection } from "../utils/prefix";
import { sortName } from "../utils/sort";

const Plan = () => {
  const { tenant } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState<Recipe[]>();
  const [totalCost, setTotalCost] = useState(0);
  const [error, setError] = useState<Error>();

  const [selectedRecipe, setSelectedRecipe] = useState<SelectProps.Option>({
    value: "",
    label: "Select recipe",
  });
  const [planned, setPlanned] = useState<Planned[]>([]);
  const [calculatedIngredients, setCalculatedIngredients] = useState<
    { item: InventoryItem; quantity: number; cost: number }[]
  >([]);

  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    setRecipes,
    setError,
    sortName
  );
  useSubscribe<Planned>(
    prefixCollection("planned", tenant),
    setPlanned,
    setError
  );

  const calculateIngredients = () => {
    const ingredientMap = new Map<
      string,
      { item: InventoryItem; quantity: number; cost: number }
    >();
    let totalCost = 0;

    planned.forEach((plannedItem) => {
      const recipe = recipes?.find((r) => r.id === plannedItem.Recipe);
      if (recipe) {
        recipe.ingredients.forEach((ingredient) => {
          const scaledQuantity =
            ingredient.quantity * parseFloat(plannedItem.Scale);
          const cost = ingredient.item.price
            ? ingredient.item.price * scaledQuantity
            : 0;
          totalCost += cost;

          if (ingredientMap.has(ingredient.item.id)) {
            const existing = ingredientMap.get(ingredient.item.id)!;
            ingredientMap.set(ingredient.item.id, {
              item: ingredient.item,
              quantity: existing.quantity + scaledQuantity,
              cost: existing.cost + cost,
            });
          } else {
            ingredientMap.set(ingredient.item.id, {
              item: ingredient.item,
              quantity: scaledQuantity,
              cost,
            });
          }
        });
      }
    });

    setCalculatedIngredients(Array.from(ingredientMap.values()));
    setTotalCost(totalCost);
  };

  useEffect(() => {
    calculateIngredients();
  }, [planned, recipes]);

  const handleAdd = () => {
    if (selectedRecipe.value !== "") {
      setPlanned((x) => {
        return [
          ...x,
          { Recipe: selectedRecipe.value ?? "", Scale: "1", id: v4() },
        ];
      });
      setSelectedRecipe({
        value: "",
        label: "Select recipe",
      });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    for (const p of planned) {
      if (validate(p.id) && version(p.id) === 4)
        await createItem<Planned>("planned", p);
      else {
        await updateItem<Planned>("planned", p.id, p);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <ContentLayout
        disableOverlap
        header={<Header variant="h1">Planning</Header>}
      >
        <div style={{ marginTop: 10, marginBottom: 200 }}>
          <ErrorBar error={error} setError={setError} />
          <SpaceBetween size="m">
            <Box>
              Select a recipe and add it. Then, scale it to your desired size.
            </Box>
            <SpaceBetween size="m" direction="horizontal">
              <FormField>Recipe: </FormField>
              <Select
                selectedOption={selectedRecipe}
                onChange={({ detail }) =>
                  setSelectedRecipe(detail.selectedOption)
                }
                options={recipes?.map((recipe) => {
                  return { value: recipe.id, label: recipe.name };
                })}
              />
              <Button
                onClick={handleAdd}
                disabled={selectedRecipe.value === ""}
                iconName="add-plus"
              >
                Add
              </Button>
              <Button
                onClick={handleSave}
                iconName={loading ? "status-in-progress" : "check"}
              >
                Save
              </Button>
            </SpaceBetween>

            <Table
              variant="embedded"
              items={planned}
              columnDefinitions={[
                {
                  header: "Name",
                  cell: (pItem) =>
                    recipes?.find((i) => i.id === pItem.Recipe)?.name,
                },
                {
                  header: "Size",
                  cell: (pItem) =>
                    (
                      (recipes?.find((i) => i.id === pItem.Recipe)?.batchSize ??
                        1) * parseFloat(pItem.Scale)
                    ).toString(),
                },
                {
                  header: "Scale",
                  cell: (pItem) => (
                    <Input
                      value={pItem.Scale.toString()}
                      onChange={({ detail }) => {
                        const mutable = [...planned];
                        setPlanned(
                          mutable.map((x) => {
                            if (x.id === pItem.id)
                              return { ...x, Scale: detail.value };
                            return x;
                          })
                        );
                      }}
                    />
                  ),
                },
                {
                  header: "",
                  cell: (pItem) => (
                    <Button
                      variant="icon"
                      iconName="close"
                      onClick={() => {
                        setPlanned(planned.filter((f) => f.id !== pItem.id));
                        if (!validate(pItem.id))
                          deleteItem("planned", pItem.id);
                      }}
                    />
                  ),
                },
              ]}
            />

            <Box>
              <Header variant="h3">Total Cost €{totalCost.toFixed(2)}</Header>
            </Box>
            <Table
              variant="embedded"
              items={calculatedIngredients}
              columnDefinitions={[
                {
                  header: "Ingredient",
                  cell: (item) => item.item.name,
                },
                {
                  header: "Quantity",
                  cell: (item) => `${item.quantity} ${item.item.unit}`,
                },
                {
                  header: "Cost",
                  cell: (item) => `€${item.cost.toFixed(2)}`,
                },
              ]}
            />
          </SpaceBetween>
        </div>
      </ContentLayout>
    </>
  );
};

export default Plan;
