import { collection, getDocs } from "@firebase/firestore";

import { db } from "../config/firebase";
import InventoryItem from "../models/InventoryItem";
import { prefixCollection } from "../utils/prefix";

export const fetchInventoryItems = async (
  tenant?: string
): Promise<InventoryItem[]> => {
  const inventoryCollection = collection(
    db,
    prefixCollection("inventory", tenant)
  ); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  const items: InventoryItem[] = querySnapshot.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        id: doc.id,
      } as InventoryItem)
  );
  return items;
};

export const fetchInventoryItem = async (
  id: string,
  tenant?: string
): Promise<InventoryItem | null> => {
  const inventoryCollection = collection(
    db,
    prefixCollection("inventory", tenant)
  ); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  const item = querySnapshot.docs
    .map((doc) => ({ ...doc.data(), id: doc.id } as InventoryItem))
    .find((item) => item.id === id);
  return item ?? null;
};

export const getInventoryItemCount = async (
  tenant?: string
): Promise<number> => {
  const inventoryCollection = collection(
    db,
    prefixCollection("inventory", tenant)
  ); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  return querySnapshot.size;
};
