export function hexToRgbObj(hex: string) {
  // Remove the # symbol if present
  hex = hex.replace("#", "");

  // Convert the hex value to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB value as a string
  return { r, g, b };
}

export function hexToRgb(hex: string): string {
  const { r, g, b } = hexToRgbObj(hex);

  // Return the RGB value as a string
  return `rgb(${r}, ${g}, ${b})`;
}
