import { Timestamp } from "@firebase/firestore";

import { toDate } from "../utils/timestamp";

export enum SuggestionType {
  FeatureRequest = "FeatureRequest",
  ServiceRequest = "ServiceRequest",
  BugReport = "BugReport",
}

export type Suggestion = {
  id: string;
  type: SuggestionType;
  title: string;
  description: string;
  createdAt: Date | Timestamp;
  updatedAt?: Date | Timestamp;
  status: SuggestionStatus;
  priority?: PriorityLevel;
  reporter: string;
  assignedTo?: string;
};

export enum SuggestionStatus {
  Open = "Open",
  InProgress = "InProgress",
  Resolved = "Resolved",
  Closed = "Closed",
}

export enum PriorityLevel {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

// Helper function to convert priority level to a numerical value
const getPriorityValue = (priority?: PriorityLevel): number => {
  switch (priority) {
    case PriorityLevel.High:
      return 3;
    case PriorityLevel.Medium:
      return 2;
    case PriorityLevel.Low:
      return 1;
    default:
      return 0;
  }
};

// Helper function to convert status to a numerical value
const getStatusValue = (status: SuggestionStatus): number => {
  switch (status) {
    case SuggestionStatus.Open:
      return 4;
    case SuggestionStatus.InProgress:
      return 3;
    case SuggestionStatus.Resolved:
      return 2;
    case SuggestionStatus.Closed:
      return 1;
    default:
      return 0;
  }
};

// Sort function
export const sortSuggestions = (a: Suggestion, b: Suggestion): number => {
  // Compare by status first
  const statusComparison = getStatusValue(b.status) - getStatusValue(a.status);
  if (statusComparison !== 0) {
    return statusComparison;
  }

  // If statuses are the same, compare by priority
  const priorityComparison =
    getPriorityValue(b.priority) - getPriorityValue(a.priority);
  if (priorityComparison !== 0) {
    return priorityComparison;
  }

  // If both status and priority are the same, compare by updatedAt date
  const dateA = toDate(a.updatedAt);
  const dateB = toDate(b.updatedAt);

  return dateB.getTime() - dateA.getTime();
};
