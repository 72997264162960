import "./print.scss";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { fetchRecipe } from "../api/recipes";
import DisplayImage from "../components/DisplayImage";
import { IngredientsList } from "../components/IngredientsList";
import StepsList from "../components/StepsList ";
import { useAuthContext } from "../contexts/AuthContext";
import { Recipe } from "../models/Recipe";

const RecipePagePrint = () => {
  const { tenant } = useAuthContext();
  const navigate = useNavigate();
  let { id } = useParams();
  const [item, setItem] = useState<Recipe | null>(null);

  useEffect(() => {
    if (!id || id === "new") return;
    fetchRecipe(id, tenant).then((i) => {
      setItem(i);
      if (i === null) {
        navigate("/Recipes");
      }
    });
  }, [id, navigate]);

  if (!item) return null;

  return (
    <div className="print">
      <div className="recipe">
        <h1>{item.name}</h1>
        <div
          style={{
            position: "absolute",
            right: 40,
            top: 40,
            height: 250,
            width: 250,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DisplayImage
            fileName="image"
            filePath={"recipes/" + id}
            round
            altText="Recipe image"
            style={{
              height: 200,
              width: 200,
              border: "solid 2px black",
            }}
          />
        </div>
        <p>
          <strong>Style:</strong> {item.style}
        </p>
        {item.description && (
          <p style={{ marginRight: 250 }}>
            <strong>Description:</strong> {item.description}
          </p>
        )}
        <p>
          <strong>Batch Size:</strong> {item.batchSize} liters
        </p>

        <IngredientsList ingredients={item?.ingredients ?? []} />
        <StepsList steps={item?.steps ?? []} />
      </div>
    </div>
  );
};

export default RecipePagePrint;
