import { ContentLayout, Header, Tabs } from "@cloudscape-design/components";
import QR from "../tools/QR";
import AbvCalculator from "../components/AbvCalculator";
import HoneyCalculator from "../components/HoneyCalculator";

const Tools = () => {
  return (
    <ContentLayout disableOverlap header={<Header variant="h1">Tools</Header>}>
      <Tabs
        tabs={[
          {
            label: "QR",
            id: "qr",
            content: <QR />,
          },
          {
            label: "ABV",
            id: "abv",
            content: <AbvCalculator />,
          },
          {
            label: "Honey",
            id: "honey",
            content: <HoneyCalculator />,
          },
        ]}
      />
    </ContentLayout>
  );
};

export default Tools;
