import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/* eslint-disable react-hooks/exhaustive-deps */
import {
  SideNavigation,
  SideNavigationProps,
} from "@cloudscape-design/components";

import { subscribeToBatchesByStatus } from "../api/batches";
import { fetchMenuItems, MenuItem, updateRatings } from "../api/menu";
import { useAuthContext } from "../contexts/AuthContext";
import { rankSort } from "../utils/elo";

const Sidebar = () => {
  const { currentUser, userObject, tenant } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [rankedItems, setRankedItems] = useState<MenuItem[]>([]);
  const [, setShopping] = useState(false);

  const [activeHref, setActiveHref] = useState(location.pathname);

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location]);

  useEffect(() => {
    const unsubscribe = subscribeToBatchesByStatus(
      ["planned"],
      tenant,
      (batches) => setShopping(batches.length > 0)
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (activeHref !== location.pathname) {
      navigate(activeHref);
    }
  }, [activeHref]);

  const getMenuItems = async () => {
    const items = await fetchMenuItems();
    setRankedItems(items.sort(rankSort));
  };

  const rankMenuItems = async (winner: string) => {
    const newItems = await updateRatings<MenuItem>(winner, "menuItems");

    setRankedItems(newItems.sort(rankSort));
  };

  useEffect(() => {
    const loc = rankedItems.find((x) =>
      location.pathname.toLowerCase().includes(x?.href ?? "NA")
    );
    if (loc) rankMenuItems(loc.id);
  }, [location.pathname]);

  useEffect(() => {
    getMenuItems();
  }, []);

  return (
    <>
      <SideNavigation
        header={{
          href: "/",
          logo: { alt: "logo", src: "/logo_2.png" },
        }}
        activeHref={activeHref}
        onFollow={(event) => {
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
          }
        }}
        items={
          !currentUser || !userObject?.approved
            ? []
            : [
                {
                  type: "link",
                  text: "Home",
                  href: "/",
                },
                ...rankedItems.map((x) => {
                  return {
                    type: "link",
                    text: x.title,
                    href: x.href ?? "",
                  } as SideNavigationProps.Item;
                }),
                {
                  type: "link",
                  text: "Settings",
                  href: "/settings",
                } as SideNavigationProps.Item,
              ]
        }
      />
    </>
  );
};

export default Sidebar;
