import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  updateDoc,
  where,
  WhereFilterOp,
} from "@firebase/firestore";

import { db } from "../config/firebase";

export const createItem = async <T extends { id?: string }>(
  collectionName: string,
  item: Omit<T, "id">
): Promise<T> => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...item,
    });

    return { ...item, id: docRef.id } as T;
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Failed to create item");
  }
};

export const updateItem = async <T extends { id: string }>(
  collectionName: string,
  itemId: string,
  item: Partial<Omit<T, "id">>
): Promise<T> => {
  try {
    const itemDoc = doc(db, collectionName, itemId);
    await updateDoc(itemDoc, { ...item });
    return { ...(item as T), id: itemId };
  } catch (error) {
    console.error("Error updating item:", error);
    throw new Error("Failed to update item");
  }
};

export const deleteItem = async (
  collectionName: string,
  itemId: string
): Promise<void> => {
  try {
    const itemDoc = doc(collection(db, collectionName), itemId);
    await deleteDoc(itemDoc);
    console.log(
      `Item with ID ${itemId} successfully deleted from ${collectionName}`
    );
  } catch (error) {
    console.error("Error deleting item:", error);
    throw new Error("Failed to delete item");
  }
};

export const fetchItems = async <T extends { id?: string }>(
  collectionName: string
): Promise<T[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as T)
    );

    return items;
  } catch (error) {
    console.error("Error fetching batches:", error);
    throw new Error("Failed to fetch batches");
  }
};

export const fetchItemsWithFilter = async <T extends { id?: string }>(
  collectionName: string,
  filterField: keyof T,
  filterValue: any,
  operator: WhereFilterOp = "=="
): Promise<T[]> => {
  try {
    const q = query(
      collection(db, collectionName),
      where(filterField as string, operator, filterValue)
    );

    const querySnapshot = await getDocs(q);

    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as T)
    );

    return items;
  } catch (error) {
    console.error("Error fetching items with filter:", error);
    throw new Error("Failed to fetch items with filter");
  }
};

export const subscribeToChanges = <T extends { id?: string }>(
  collectionName: string,
  onChange: (items: T[]) => void,
  onError: (error: Error) => void,
  itemId?: string
): (() => void) => {
  const collectionRef = collection(db, collectionName);
  const unsubscribe = itemId
    ? onSnapshot(
        doc(collectionRef, itemId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const item = { ...docSnapshot.data(), id: docSnapshot.id } as T;
            onChange([item]);
          } else {
            onError(new Error("Document does not exist"));
          }
        },
        (error) => {
          console.error("Error subscribing to document changes:", error);
          onError(new Error("Failed to subscribe to document changes"));
        }
      )
    : onSnapshot(
        collectionRef,
        (querySnapshot: QuerySnapshot<DocumentData>) => {
          const items: T[] = querySnapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as T)
          );
          onChange(items);
        },
        (error) => {
          console.error("Error subscribing to collection changes:", error);
          onError(new Error("Failed to subscribe to collection changes"));
        }
      );

  return unsubscribe;
};

export const getCount = async (collectionName: string): Promise<number> => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.size;
};
