import React from "react";
import { Spinner, SpinnerProps } from "@cloudscape-design/components";

type LoaderProps = {
  isLoading: boolean;
  children: React.ReactNode;
  size?: SpinnerProps.Size;
};

const Loader: React.FC<LoaderProps> = ({ isLoading, size, children }) => {
  return isLoading ? (
    <Spinner size={size ?? "normal"} variant="normal" />
  ) : (
    <>{children}</>
  );
};

export default Loader;
