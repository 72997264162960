import { logout, signInWithGoogle, signInWithMicrosoft } from "../auth";

import { Button, SpaceBetween } from "@cloudscape-design/components";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Loginout = () => {
  const { currentUser } = useAuthContext();
  const navigate = useNavigate();
  return (
    <div style={{ paddingLeft: 28 }}>
      {currentUser ? (
        <Button
          onClick={() => {
            logout();
            navigate("/");
          }}
          variant="inline-link"
        >
          Logout
        </Button>
      ) : (
        <SpaceBetween size="m" direction="vertical">
          <Button onClick={signInWithGoogle} variant="inline-link">
            Login (Google)
          </Button>
          <Button onClick={signInWithMicrosoft} variant="inline-link">
            Login (Microsoft)
          </Button>
        </SpaceBetween>
      )}
    </div>
  );
};

export default Loginout;
