import {
  Badge,
  Box,
  Button,
  Cards,
  ColumnLayout,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect } from "react";
import { subscribeToUnapprovedUsers, updateUser } from "../api/approval";

import { Image } from "@chakra-ui/react";
import { UserData } from "../models/UserData";
import { useAuthContext } from "../contexts/AuthContext";

const Approve = () => {
  const { currentUser, userObject } = useAuthContext();
  const [toApprove, setToApprove] = React.useState<UserData[]>([]);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToUnapprovedUsers(setToApprove);

    return () => {
      unsubscribe();
    };
  }, []);

  if (!currentUser) return null;
  if (!userObject) return null;
  if (!userObject.approved) return null;
  if (userObject.role !== "admin" && userObject.role !== "approver")
    return null;

  if (toApprove.length === 0) return null;

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleDismiss = () => {
    setShowModal(false);
  };

  const handleApprove = (user: UserData) => {
    updateUser(user.uid, { ...user, approved: true, denied: false });
  };

  const handleDeny = (user: UserData) => {
    updateUser(user.uid, { ...user, approved: false, denied: true });
  };

  const handleRoleUp = (user: UserData) => {
    const newRole = user.role === "approver" ? "admin" : "approver";
    updateUser(user.uid, { ...user, role: newRole });
  };

  const handleRoleDown = (user: UserData) => {
    const newRole = user.role === "admin" ? "approver" : "user";
    updateUser(user.uid, { ...user, role: newRole });
  };

  return (
    <div style={{ paddingLeft: 28 }}>
      <Button variant="inline-link" onClick={handleOpen}>
        Approve <Badge color="red">{toApprove.length}</Badge>
      </Button>
      <Modal
        header="Approve Users"
        visible={showModal}
        onDismiss={handleDismiss}
      >
        <ColumnLayout columns={1} variant="default">
          <p>
            The following users are awaiting approval. Please review and approve
            or deny.
          </p>

          <Cards
            items={toApprove}
            visibleSections={["info", "image", "actions"]}
            cardDefinition={{
              header: (item: UserData) => item.name,

              sections: [
                {
                  id: "image",
                  content: (item: UserData) => (
                    <Image
                      src={
                        item.photoURL ??
                        "https://ui-avatars.com/api/?name=" +
                          item.name +
                          "&background=0D8ABC&color=fff&size=128&rounded=true&bold=true"
                      }
                      alt="placeholder"
                    />
                  ),
                  width: 50,
                },
                {
                  id: "info",
                  header: "Info",
                  content: (item: UserData) => (
                    <SpaceBetween size="m">
                      <Box>{item.email}</Box>
                      <SpaceBetween size="m" direction="horizontal">
                        <Button
                          disabled={item.role === "admin"}
                          onClick={() => handleRoleUp(item)}
                          variant="icon"
                          iconName="angle-up"
                        />
                        <Button
                          disabled={item.role === "user"}
                          onClick={() => handleRoleDown(item)}
                          variant="icon"
                          iconName="angle-down"
                        />

                        <Box variant="awsui-key-label">{item.role}</Box>
                      </SpaceBetween>
                    </SpaceBetween>
                  ),
                  width: 50,
                },
                {
                  id: "actions",
                  content: (item: UserData) => (
                    <div style={{ float: "right" }}>
                      <SpaceBetween size="m" direction="horizontal">
                        <Button
                          onClick={() => handleApprove(item)}
                          variant="primary"
                          iconName="check"
                        >
                          Approve
                        </Button>
                        <Button
                          iconName="close"
                          onClick={() => handleDeny(item)}
                          variant="link"
                        >
                          Deny
                        </Button>
                      </SpaceBetween>
                    </div>
                  ),
                  width: 100,
                },
              ],
            }}
          />
        </ColumnLayout>
      </Modal>
    </div>
  );
};

export default Approve;
