import { GravitySample } from '../models/Sampling';

export const sortName = <T extends { name: string }>(a: T, b: T): number => {
  return a.name.localeCompare(b.name);
};

export const sortGravitySamples = (
  a: GravitySample,
  b: GravitySample
): number => {
  // Convert date to timestamp if it is not already a timestamp
  const dateA = a.date instanceof Date ? a.date.getTime() : a.date;
  const dateB = b.date instanceof Date ? b.date.getTime() : b.date;

  // Compare dates
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;

  // If dates are equal, compare temperatures
  if (a.temperature < b.temperature) return -1;
  if (a.temperature > b.temperature) return 1;

  // If temperatures are also equal, return 0 (indicating equal)
  return 0;
};
