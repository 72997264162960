/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { fetchImageUrl } from "../api/storage";

interface DisplayImageProps {
  filePath: string;
  fileName: string;
  altText: string;
  round?: boolean;
  style?: React.CSSProperties;
  hide?: boolean;
}

const DisplayImage: React.FC<DisplayImageProps> = ({
  fileName,
  filePath,
  altText,
  round,
  hide,
  style,
}) => {
  const [url, setUrl] = useState<string>("");

  const fetchUrl = async () => {
    const url = await fetchImageUrl(filePath + "/" + fileName);
    if (url) {
      setUrl(url);
    } else {
      setUrl("");
    }
  };

  React.useEffect(() => {
    fetchUrl();
  }, [fileName, filePath]);

  return url === "" || hide ? null : (
    <img
      src={url}
      alt={altText}
      style={{ ...style, borderRadius: round ? "50%" : "0%" }}
    />
  );
};

export default DisplayImage;
