import {
  Box,
  Button,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";

import React from "react";
import tips from "../json/hint.json";

const Hint = () => {
  // gethint by the day of the year modulo the number of hints
  const hint = (index: number) => tips.meadMakingTips[index];

  const [hintIndex, setHintIndex] = React.useState(
    new Date().getDay() % tips.meadMakingTips.length
  );

  return (
    <ExpandableSection headerText={hint(hintIndex).split(":")[0]}>
      <SpaceBetween size="m" className="pl-2 pr-2">
        <Box>{hint(hintIndex).split(":")[1]}</Box>
        <div style={{ float: "right" }}>
          <Button
            variant="normal"
            onClick={() => {
              setHintIndex((hintIndex + 1) % tips.meadMakingTips.length);
            }}
            iconName="angle-right"
          ></Button>
        </div>
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default Hint;
