// pages/Settings.tsx

import { useLocalStorage } from "react-use";

import { ContentLayout, Header, Tabs } from "@cloudscape-design/components";

import { DataImport } from "../components/DataImport";
import Users from "../components/Users";
import { useAuthContext } from "../contexts/AuthContext";
import { Settings } from "../models/Settings";
import ApiKeyTab from "./ApiKeyTab";
import Tenants from "./Tenants";

export const initalSettings: Settings = { id: "", integrations: [] };

const SettingsPage = () => {
  const { userObject } = useAuthContext();
  const [tab, setTab] = useLocalStorage("settings-tab", "");

  return (
    <ContentLayout
      header={<Header variant="h1">Settings</Header>}
      disableOverlap
    >
      <div style={{ marginTop: 10 }}>
        {userObject && (
          <Tabs
            onChange={({ detail }) => setTab(detail.activeTabId)}
            activeTabId={tab}
            tabs={[
              {
                label: "Users (Admin)",
                id: "users",
                content: <Users />,
                disabled: !["god", "admin"].includes(userObject.role),
              },
              {
                label: "Api keys (Admin)",
                id: "apikeys",
                content: <ApiKeyTab />,
                disabled: !["god", "admin"].includes(userObject.role),
              },
              {
                label: "Tenants (God)",
                id: "tenants",
                content: <Tenants />,
                disabled: !["god"].includes(userObject.role),
              },
              {
                label: "Data import (God)",
                id: "data-import",
                content: <DataImport />,
                disabled: !["god"].includes(userObject.role),
              },
            ]}
          />
        )}
      </div>
    </ContentLayout>
  );
};

export default SettingsPage;
