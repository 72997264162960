export interface SqInventoryItem {
  variantId: string; // Unique ID for the InventoryItem
  sku: string; // Stock keeping unit (SKU) code
  descriptor: string; // Description generated using product title and attributes
  isUnlimited: boolean; // Indicates if stock is tracked
  quantity: number; // Current amount in stock or last known stock amount
}

export enum OperationType {
  Increment = "INCREMENT",
  Decrement = "DECREMENT",
  SetFinite = "SET_FINITE",
  SetUnlimited = "SET_UNLIMITED",
}

export interface SqAdjustment {
  variantId: string;
  quantity: number;
  operation: OperationType;
}
export interface SqPagination {
  hasNextPage: boolean; // Indicates if another page of data is available
  nextPageCursor: string; // Cursor for the next page of data
  nextPageUrl: string; // URL to request the next page of data
}

export interface SqInventoryResponse {
  inventory: SqInventoryItem[]; // Array of InventoryItem resources
  pagination: SqPagination; // Pagination details for iterating on InventoryItems
}

interface InventoryAdjustmentPayload {
  incrementOperations?: { variantId: string; quantity: number }[];
  decrementOperations?: { variantId: string; quantity: number }[];
  setFiniteOperations?: { variantId: string; quantity: number }[];
  setUnlimitedOperations?: string[];
}

const proxy = (url: string, apiKey: string) =>
  `https://europe-west1-producery-7c030.cloudfunctions.net/proxy?url=${encodeURIComponent(
    url
  )}&apiKey=${apiKey}`;

const squareUrls = {
  retrieveAllInventory: (api: string, cursor?: string) =>
    `https://api.squarespace.com/${api}/commerce/inventory${
      cursor ? "?cursor=" + cursor : ""
    }`,
  adjustInventory:
    "https://api.squarespace.com/1.0/commerce/inventory/adjustments",
};

export const createInventoryAdjustmentPayload = (
  adjustments: SqAdjustment[]
): InventoryAdjustmentPayload => {
  const incrementOperations: { variantId: string; quantity: number }[] = [];
  const decrementOperations: { variantId: string; quantity: number }[] = [];
  const setFiniteOperations: { variantId: string; quantity: number }[] = [];
  const setUnlimitedOperations: string[] = [];

  adjustments.forEach((adjustment) => {
    switch (adjustment.operation) {
      case OperationType.Increment:
        incrementOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.Decrement:
        decrementOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.SetFinite:
        setFiniteOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.SetUnlimited:
        setUnlimitedOperations.push(adjustment.variantId);
        break;
    }
  });

  return {
    incrementOperations: incrementOperations.length
      ? incrementOperations
      : undefined,
    decrementOperations: decrementOperations.length
      ? decrementOperations
      : undefined,
    setFiniteOperations: setFiniteOperations.length
      ? setFiniteOperations
      : undefined,
    setUnlimitedOperations: setUnlimitedOperations.length
      ? setUnlimitedOperations
      : undefined,
  };
};

export const retrieveAllInventory = async (apiKey: string) => {
  const url = proxy(squareUrls.retrieveAllInventory("1.0"), apiKey);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = (await response.json()) as SqInventoryResponse;

    // Sort the inventory items by descriptor
    data.inventory.sort((a, b) => a.descriptor.localeCompare(b.descriptor));

    return data;
  } catch (error) {
    console.error("Error fetching inventory:", error);
    return null;
  }
};

export const pushInventoryToApi = async (
  payload: InventoryAdjustmentPayload,
  apiKey: string
) => {
  const url = proxy(squareUrls.adjustInventory, apiKey);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: payload }), // Add the payload to the body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error pushing inventory to API:", error);
    throw error;
  }
};
