import { Route, Routes } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";
import Home from "../pages/Home";
import NotApproved, { Denied } from "../pages/NotApproved";
import RecipePagePrint from "../pages/RecipePagePrint";

export const PrintRouting = () => {
  const { currentUser, userObject } = useAuthContext();

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    );
  }

  if (userObject?.denied) {
    return (
      <Routes>
        <Route path="/" element={<Denied />} />
      </Routes>
    );
  }

  if (!userObject?.approved) {
    return (
      <Routes>
        <Route path="/" element={<NotApproved />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/recipes/:id/print" element={<RecipePagePrint />} />
    </Routes>
  );
};
