import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "@firebase/firestore";

import { db } from "../config/firebase";
import { ShoppingListStatus } from "../models/ShoppingList";
import { prefixCollection } from "../utils/prefix";

export const fetchShoppingList = async (tenant: string) => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("shopping", tenant))
    );
    const fetchedRecipes: ShoppingListStatus[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as ShoppingListStatus)
    );
    return fetchedRecipes;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const createShoppingListStatus = async (
  item: Omit<ShoppingListStatus, "id">,
  tenant: string
) => {
  try {
    const docRef = await addDoc(
      collection(db, prefixCollection("shopping", tenant)),
      item
    );
    return docRef.id;
  } catch (error) {
    console.error("Error adding inventory item:", error);
    throw error; // You might want to handle this error differently
  }
};

export const updateShoppingListStatus = async (
  item: ShoppingListStatus,
  tenant: string
) => {
  try {
    const itemRef = doc(db, prefixCollection("shopping", tenant), item.id);
    await updateDoc(itemRef, item);
  } catch (error) {
    console.error("Error updating inventory item:", error);
    // Handle the error (e.g., show an error message to the user)
  }
};
